import Splide from "@splidejs/splide";

export default class Slider {
    constructor() {
        this.everyBanerSlider = [];
        this.banersSlider = [];

        window.addEventListener("DOMContentLoaded", () => {
            this.initialize();
        });

        if (window.acf) {
            window.acf.addAction('render_block_preview/type=slider', () => {
                this.initialize();
            });
        }
    }

    initialize() {

        this.everyBanerSlider.forEach(slider => slider.destroy(true));
        this.banersSlider = document.querySelectorAll(".banners__slider");
        if (this.banersSlider.length) {
            this.initializeBannersSlider();
        }
    }

    initializeBannersSlider() {
        this.banersSlider.forEach((item) => {
            this.everyBanerSlider.push(
                new Splide(item, {
                    pagination: true,
                    perPage: 1,
                    perMove: 1,
                    arrows: "slider",
                    trimSpace: true,
                }).mount()
            );
        });
    }
}

new Slider()